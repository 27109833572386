.loading-spinner {
    position:fixed;
    top:50%;
    left:50%;
    margin: 2px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    animation: loading-spinner-spin 1s linear infinite;
    border: 2px solid var(--loading-spinner-outer-color, rgba(0, 0, 0, 0.3));
    border-top: 2px solid var(--loading-spinner-inner-color, rgba(0, 0, 0, 1));
}

@keyframes loading-spinner-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
