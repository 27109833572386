/* CSS  */

.lightClass{
    background-color:rgb(152, 230, 255);
}
.darkClass{
    background-color: #eee;
}

.appBarColor {
    background-color: #2196f3;
}

.text {
    text-align: center;
    padding-bottom: 25px;
    padding-top: 20px;
    color: #bfc6cc;
}

.link {
    Text-decoration: none;
}
.linkText:hover{
    color: #aab0b4;
}

.margin {
    /* background-color: yellow; */
    /* padding-top: 20px;  */
    margin-left: 20px; 
    margin-right: 20px; 
    margin-top: 0;
}

.forgot {
    padding-top: 20px;  
    margin-left: 20px; 
    margin-right: 20px; 
    margin-top: 0;
}

.button{
    margin-left:5px;
    margin-right: 5px; 
}

.container {
    margin-top: 20%;
    width: 450px;
}

.footer{
    background-color: grey;
}


.LoginProperties{
    width: 420px;    
    margin: 0 auto;    
    display: block;    
    text-align: center;    
}

.signinProperties{
    margin: 0 auto;    
    display: block;    
}

.panelStyle {
    border: 0;
    padding-left: 20;
    padding-right: 20;
    width: 300;
}
.buttonStyle {
    margin-bottom: 0;
}
.arrowBack {
    color: white !important;
    margin-right: 6px !important;
    height: 26px 
}
.MuiInput-root-343.MuiInput-disabled-346 {
    color: black !important;
    opacity: 1;
}

.MuiSelect-select-357.MuiSelect-disabled-359 {
    cursor: no-drop !important;
}